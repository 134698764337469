<template>
  <a-menu
      v-model:openKeys="openKeys"
      v-model:selectedKeys="selectedKeys"
      :mode="mode"
      :theme="darkMode ? 'dark' : 'light'"
  >
    <template v-for="(menu, i) in menus" :key="menu.path + i">
      <tree-menu @toggleCollapsed="toggleCollapsed" :menu="menu"></tree-menu>
    </template>
  </a-menu>
</template>
<script>
import {
  computed,
  reactive,
  ref,
  toRefs,
  watch,
  watchEffect,
  defineComponent,
} from "vue";
import VueTypes from "vue-types";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import TreeMenu from "./TreeMenu";

export default defineComponent({
  name: "AsideItems",
  components: {
    TreeMenu
  },
  props: {
    toggleCollapsed: VueTypes.func,
    events: VueTypes.object,
  },
  setup(props) {
    const store = useStore();
    const darkMode = computed(() => store.state.themeLayout.data);
    const menus = computed(() => store.getters.menus);
    const mode = ref("inline");
    const {events} = toRefs(props);
    const {
      modeChangeDark,
      modeChangeLight,
      modeChangeSideNav,
    } = events.value;

    const router = computed(() => useRoute());
    const state = reactive({
      selectedKeys: ["home"],
      openKeys: ["dashboard"],
      preOpenKeys: ["dashboard"],
    });

    watchEffect(() => {
      if (router.value.matched.length) {
        if (router.value.matched.length > 2) {
          state.selectedKeys = [router.value.matched[2].path];
          state.openKeys = [router.value.matched[1].name];
          state.preOpenKeys = [router.value.matched[1].name];
        } else if (router.value.matched.length > 3) {
          state.selectedKeys = [router.value.matched[3].path];
          state.openKeys = [router.value.matched[1].name];
          state.preOpenKeys = [router.value.matched[1].name];
        } else {
          if (router.value.matched[1]) {
            state.selectedKeys = [router.value.matched[1].path];
            state.openKeys = [router.value.matched[1].name];
            state.preOpenKeys = [router.value.matched[1].name];
          }
        }
      }
    });

    watch(
        () => state.openKeys,
        (val, oldVal) => {
          state.preOpenKeys = oldVal;
        }
    );

    return {
      mode,
      ...toRefs(state),
      darkMode,
      menus,
      modeChangeDark,
      modeChangeLight,
      modeChangeSideNav,
    };
  },
});
</script>
