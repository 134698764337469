<template>
  <div>
    <template v-if="menu.children && menu.children.length > 0">
      <a-sub-menu :key="menu.path">
        <template v-slot:title>
          <cds-feather-icons :type="menu.icon" />
          <span>{{ menu.menuName }}</span>
        </template>
        <tree-menu
            v-for="(child, i) in menu.children"
            :key="child.path + i"
            :menu="child"
        ></tree-menu>
      </a-sub-menu>
    </template>

    <template v-else>
      <a-menu-item @click="$emit('toggleCollapsed')" :key="menu.path">
        <router-link :to="!menu.menuId ? '/' : menu.path">
          <cds-feather-icons :type="menu.icon" />
          <span>{{ menu.menuName }}</span>
        </router-link>
      </a-menu-item>
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import VueTypes from 'vue-types';

export default defineComponent({
  name: 'TreeMenu',
  props: {
    menu: VueTypes.object,
  },
});
</script>

<style scoped></style>
