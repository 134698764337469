<template>
  <InfoWraper>
    <!--    <Settings />-->

    <div class="nav-author">
      <cds-popover placement="bottomRight" action="click">
        <template v-slot:content>
          <UserDropDwon>
            <div class="user-dropdwon" v-if="userInfo">
              <figure class="user-dropdwon__info">
                <a-avatar
                  v-if="userInfo"
                  class="mr-20"
                  shape="square"
                  :size="70"
                  :src="userInfo.avatar"
                >
                </a-avatar>
                <figcaption>
                  <cds-heading as="h5">{{ userInfo.fullName }}</cds-heading>
                  <p class="text-center" v-if="userInfo">
                    {{ userInfo.username }}
                  </p>
                  <p class="text-center" v-if="userInfo.investor">
                    {{ userInfo.investor.name }}
                  </p>
                </figcaption>
              </figure>
              <ul class="user-dropdwon__links">
                <li>
                  <a @click="router('profile')">
                    <cds-feather-icons type="user" />Thông tin cá nhân</a
                  >
                </li>
                <li>
                  <a @click="router('account')">
                    <cds-feather-icons type="settings" />Cài đặt tài khoản</a
                  >
                </li>
                <li>
                  <a @click="router('password')">
                    <cds-feather-icons type="key" />Đổi mật khẩu</a
                  >
                </li>
              </ul>
              <a @click="SignOut" class="user-dropdwon__bottomAction" href="#">
                <LogoutOutlined /> Đăng xuất
              </a>
            </div>
          </UserDropDwon>
        </template>
        <a to="#" class="head-example">
          <a-avatar v-if="userInfo" :size="40" :src="userInfo.avatar">
          </a-avatar>
        </a>
      </cds-popover>
    </div>
  </InfoWraper>
</template>

<script>
import { InfoWraper, UserDropDwon } from './auth-info-style';
import Settings from './Settings';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { LogoutOutlined } from '@ant-design/icons-vue';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'AuthInfo',
  components: {
    InfoWraper,
    UserDropDwon,
    Settings,
    LogoutOutlined,
  },
  data() {
    return {
      flag: 'english',
    };
  },
  setup() {
    const { dispatch, state } = useStore();
    const { push } = useRouter();
    const isLoading = computed(() => state.firebase.loading);
    const SignOut = (e) => {
      e.preventDefault();
      push('/auth/login');
      dispatch('logOut');
    };

    const router = (page) => push(`/settings/profile-settings/${page}`);
    const userInfo = computed(() => state.auth.userInfo);

    return {
      SignOut,
      router,
      isLoading,
      userInfo,
    };
  },
  methods: {
    onFlagChangeHandle: function (value) {
      this.flag = value;
    },
  },
});
</script>
