<template>
  <div class="settings">
    <cds-popover placement="bottomRight" action="click">
      <template v-slot:content>
        <SettingDropdwon>
          <div class="setting-dropdwon">
            <a-row :gutter="10" :class="rtl ? 'ant-row-rtl' : ''">
              <a-col :sm="12" @click="darkMode(true)">
                <figure class="setting-dropdwon__single d-flex">
                  <img
                    :src="require('../../../static/img/icon/014-document.png')"
                    alt=""
                  />
                  <figcaption>
                    <cds-heading as="h5">Dark Theme</cds-heading>
                  </figcaption>
                </figure>
              </a-col>
              <a-col :sm="12" @click="darkMode(false)">
                <figure class="setting-dropdwon__single d-flex">
                  <img
                    :src="
                      require('../../../static/img/icon/015-color-palette.png')
                    "
                    alt=""
                  />
                  <figcaption>
                    <cds-heading as="h5">Light theme</cds-heading>
                  </figcaption>
                </figure>
              </a-col>
            </a-row>
          </div>
        </SettingDropdwon>
      </template>
      <a to="#" class="head-example">
        <cds-feather-icons type="settings" size="20" />
      </a>
    </cds-popover>
  </div>
</template>

<script>
import { SettingDropdwon } from "./auth-info-style";
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Settings",
  components: {
    SettingDropdwon,
  },
  setup() {
    const { state, dispatch } = useStore();
    const rtl = computed(() => state.themeLayout.rtlData);
    const darkMode = (darkMode) => {
      dispatch('changeDarkMode', darkMode)
    }
    return {
      rtl,
      darkMode
    };
  },
});
</script>
